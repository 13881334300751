import React from "react"
import { Link } from "gatsby"
import { Seo } from "../components/Seo/Seo"

function NotFoundPage() {
  return (
    <React.Fragment>
      <Seo title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... sorry.</p>
      <Link to="/">Go to the homepage →</Link>
    </React.Fragment>
  )
}

export default NotFoundPage
